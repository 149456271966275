import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Modal, Table, TableBody, Card, Grid } from "@mui/material";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  CancelButton,
  CloseButton,
  ConfirmButton,
  SectionTitle,
  SectionSubtitle,
  ModalWrapper,
  StyledButton,
  ASSIGN_USERS_TABLE_HEADERS,
  ProjectRow,
  ProjectCell,
  TableCard,
  ListHead,
  ModalHeader,
} from "../commonComponents/commonComponents";

// DELETE PROJECT MODAL //
export const TermsModal = (props) => {
  return (
    <Modal open={props.modalOpen} key="TermsModal">
      <ModalWrapper>
        <ModalHeader
          close_action={props.handleModalOpen}
          title={"Terms of Service"}
        />
        <SectionSubtitle
          subtitle_text={
            "Mikro has a few terms and conditions which need your approval."
          }
        />
        <SectionSubtitle
          subtitle_text={
            'Please read the terms and conditions. If you agree, press "ok" to proceed'
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              height: "25vh",
              backgroundColor: "AliceBlue",
              overflowY: "scroll",
            }}
          >
            What is Lorem Ipsum?
            <br />
            <br />
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
            <br />
            <br />
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
            <br />
            <br />
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
            <br />
            <br />
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum. Why do we use it? It is a long established fact that a reader
            will be distracted by the readable content of a page when looking at
            its layout. The point of using Lorem Ipsum is that it has a
            more-or-less normal distribution of letters, as opposed to using
            'Content here, content here', making it look like readable English.
            <br />
            <br />
            Many desktop publishing packages and web page editors now use Lorem
            Ipsum as their default model text, and a search for 'lorem ipsum'
            will uncover many web sites still in their infancy.
            <br />
            <br />
            Various versions have evolved over the years, sometimes by accident,
            sometimes on purpose (injected humour and the like).
            <br />
            <br />
            Where does it come from?
            <br />
            <br />
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            <br />
            <br />
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old.
            <br />
            <br />
            Richard McClintock, a Latin professor at Hampden-Sydney College in
            Virginia, looked up one of the more obscure Latin words,
            consectetur, from a Lorem Ipsum passage, and going through the cites
            of the word in classical literature, discovered the undoubtable
            source.
            <br />
            <br />
            Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
            Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
            written in 45 BC.
            <br />
            <br />
            This book is a treatise on the theory of ethics, very popular during
            the Renaissance.
            <br />
            <br />
            The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
            from a line in section 1.10.32.
            <br />
            <br />
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested.
            <br />
            <br />
            Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
            Cicero are also reproduced in their exact original form, accompanied
            by English versions from the 1914 translation by H. Rackham.
            <br />
            <br />
            Where can I get some?
            <br />
            <br />
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
            <br />
            <br />
            If you are going to use a passage of Lorem Ipsum, you need to be
            sure there isn't anything embarrassing hidden in the middle of text.
            <br />
            <br />
            All the Lorem Ipsum generators on the Internet tend to repeat
            predefined chunks as necessary, making this the first true generator
            on the Internet.
            <br />
            <br />
            It uses a dictionary of over 200 Latin words, combined with a
            handful of model sentence structures, to generate Lorem Ipsum which
            looks reasonable.
            <br />
            <br />
            The generated Lorem Ipsum is therefore always free from repetition,
            injected humour, or non-characteristic words etc.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1vh",
            }}
          >
            <SectionSubtitle subtitle_text="I agree to the terms and conditions" />
            <input
              type="checkbox"
              value="public"
              name="public"
              onChange={() => props.handleSetTermsAgreement()}
              checked={props.termsAgreement === true}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "1vh",
            marginBottom: "2vh",
          }}
        >
          <StyledButton
            button_text={"cancel"}
            button_action={() => props.handleModalOpen()}
          />
          <StyledButton
            button_text={"ok"}
            button_action={() => props.handleModalOpen()}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};
