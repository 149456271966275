import React, { useContext, useEffect } from "react";
import { DataContext } from "common/DataContext";
import { AuthContext } from "common/AuthContext";
import useToggle from "../../hooks/useToggle.js";
import Sidebar from "../sidebar/sidebar";
import {
  TableCard,
  CardMediaStyle,
  SectionTitle,
  StyledButton,
  ConfirmModalCommon,
} from "components/commonComponents/commonComponents";
import "./styles.css";

export const AdminAccountPage = () => {
  const {
    firstName,
    lastName,
    OSMname,
    city,
    country,
    email,
    payEmail,
    sidebarOpen,
    fetchUserDetails,
    updateUserDetails,
    handleUserDetailsStates,
    handleSetSidebarState,
    history,
  } = useContext(DataContext);

  const { refresh, user } = useContext(AuthContext);
  const [modalOpen, toggleModalOpen] = useToggle(false);

  useEffect(() => {
    if (user) {
      refresh();
    }
    if (user === null) {
      history("/login");
    }
    if (user !== null && user.role !== "admin") {
      history("/login");
    }
    fetchUserDetails();
    // eslint-disable-next-line
  }, []);

  const handleModalOpen = () => {
    toggleModalOpen();
  };

  const handleConfirmUpdateUserDetails = () => {
    handleModalOpen();
  };

  const handleUpdateUserDetails = () => {
    updateUserDetails();
    handleModalOpen();
  };

  return (
    <>
      <ConfirmModalCommon
        modal_open={modalOpen}
        handleOpenCloseModal={handleModalOpen}
        interrogative={"Are you sure you want to update these details?"}
        button_1_text="Confirm"
        button_1_action={handleUpdateUserDetails}
        button_2_text="Cancel"
        button_2_action={handleModalOpen}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <StyledButton
          button_text={"submit"}
          button_action={handleConfirmUpdateUserDetails}
        />
        <StyledButton
          button_text={"password reset"}
          button_action={(onclick) =>
            window.open(
              "https://my.kaart.com/password-reset",
              "_blank",
              "width=800, height=600"
            )
          }
        />
      </div>
      <div
        style={{
          height: "50%",
          marginTop: "1vh",
        }}
      >
        <TableCard>
          <CardMediaStyle />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "15vh",
              marginTop: "0vh",
              marginBottom: "0vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"First Name:"} bold={true} />
              <input
                type="text"
                value={firstName}
                onChange={(e) => handleUserDetailsStates("first_name", e)}
                style={{
                  height: "5vh",
                  marginRight: "2vw",
                  width: "13vw",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"Last Name:"} bold={true} />
              <input
                type="text"
                value={lastName}
                onChange={(e) => handleUserDetailsStates("last_name", e)}
                style={{
                  height: "5vh",
                  marginRight: "2vw",
                  width: "13vw",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"OSM Username:"} bold={true} />
              <input
                type="text"
                value={OSMname}
                onChange={(e) => handleUserDetailsStates("osm_name", e)}
                style={{
                  height: "5vh",
                  marginRight: "2vw",
                  width: "13vw",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "15vh",
              marginTop: "0vh",
              marginBottom: "0vh",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"City:"} bold={true} />
              <input
                type="text"
                value={city}
                onChange={(e) => handleUserDetailsStates("city", e)}
                style={{
                  height: "5vh",
                  marginRight: "5vw",
                  width: "15vw",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"Country:"} bold={true} />
              <input
                type="text"
                value={country}
                onChange={(e) => handleUserDetailsStates("country", e)}
                style={{
                  height: "5vh",
                  marginRight: "5vw",
                  width: "15vw",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "15vh",
              justifyContent: "center",
              marginTop: "0vh",
              marginBottom: "0vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"Personal Email:"} bold={true} />
              <input
                type="text"
                value={email}
                onChange={(e) => handleUserDetailsStates("email", e)}
                style={{
                  height: "5vh",
                  marginRight: "5vw",
                  width: "20vw",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SectionTitle title_text={"Payment Email:"} bold={true} />
              <input
                type="text"
                value={payEmail}
                onChange={(e) => handleUserDetailsStates("pay_email", e)}
                style={{
                  height: "5vh",
                  marginRight: "5vw",
                  width: "20vw",
                }}
              />
            </div>
          </div>
        </TableCard>
      </div>
    </>
  );
};
