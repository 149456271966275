import React, { useEffect, useState, useContext } from "react";
import { Modal, Table, TableBody, TablePagination } from "@mui/material";
import { DataContext } from "common/DataContext";
import {
  ProjectRow,
  CardMediaStyle,
  TableCard,
  ProjectCell,
  ListHead,
  CloseButton,
  SectionTitle,
  SectionSubtitle,
  ModalWrapper,
  ModalButtons,
  CancelButton,
} from "../commonComponents/commonComponents";

export const USER_TRAINING_HEADERS = [
  { id: "name", label: "Title", alignRight: false },
  { id: "difficulty", label: "Difficulty", alignRight: false },
  { id: "point_value", label: "Point Value", alignRight: false },
  { id: "link", label: "Link", alignRight: false },
];

export const TrainingQuizModal = (props) => {
  const { shuffleArray } = useContext(DataContext);

  const [answers, setAnswers] = useState([]);

  const [tempAnswer, setTempAnswer] = useState(null);

  useEffect(() => {
    if (
      props.questions.length > 0 &&
      props.questions[0].questions[props.questionIndex]
    ) {
      let tempArray = [];

      const currentQuestion = props.questions[0].questions[props.questionIndex];

      if (currentQuestion.incorrect) {
        for (let i = 0; i < currentQuestion.incorrect.length; i++) {
          tempArray.push(currentQuestion.incorrect[i]);
        }
      }

      if (currentQuestion.correct) {
        tempArray.push(currentQuestion.correct);
      }

      setAnswers(shuffleArray(tempArray));
    }

    // eslint-disable-next-line
  }, [props.questionIndex, props.questions]);

  const handleSetAnswer = (value) => {
    let tempArray = props.results;
    setTempAnswer(value);
    if (value === props.questions[0].questions[props.questionIndex].correct) {
      tempArray.push(true);
    } else {
      tempArray.push(false);
    }
    props.setResults(tempArray);
  };

  return (
    <Modal open={props.quizOpen} key="add">
      <ModalWrapper>
        <CloseButton close_action={props.handleQuizOpen} />
        <SectionTitle title_text={`Test our for training: ${props.title}`} />

        <div
          style={{
            width: "100%",
            backgroundColor: "black",
            height: ".05vh",
            marginBottom: "2vh",
          }}
        />
        {props.questions && props.questions.length > 0 ? (
          <>
            <SectionSubtitle
              subtitle_text={`Question ${props.questionIndex + 1}: ${
                props.questions[0].questions[props.questionIndex]?.question ||
                "Undefined Question"
              }?`}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "6vw",
                marginBottom: "3vh",
              }}
            >
              {answers.map((answer, index) => (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <input
                    type="radio"
                    value={answer}
                    name="private"
                    onChange={() => handleSetAnswer(answer)}
                    checked={tempAnswer === answer}
                    style={{ marginLeft: "6.5vw" }}
                  />
                  <SectionSubtitle subtitle_text={answer} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        <div style={{ marginBottom: "1vh" }}>
          <ModalButtons
            confirm_text={props.confirmButtonText}
            confirm_action={() => props.handleChangeQuestionIndex()}
            cancel_text={"Cancel"}
            cancel_action={props.handleQuizOpen}
          />
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export const UserTrainingTable = (props) => {
  const updateData = (sortedData) => {
    props.setOrgTrainings(sortedData);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "88vh",
      }}
    >
      <TableCard>
        <CardMediaStyle />
        <Table style={{}}>
          <ListHead
            headLabel={USER_TRAINING_HEADERS}
            tableData={props.orgTrainings}
            updateData={updateData}
          />
          <TableBody>
            {props.orgTrainings &&
              props.orgTrainings
                .slice(
                  props.page * props.rowsPerPage,
                  props.page * props.rowsPerPage + props.rowsPerPage
                )
                .map((row) => {
                  const {
                    id,
                    title,
                    training_url,
                    training_type,
                    point_value,
                    difficulty,
                  } = row;
                  return (
                    <ProjectRow
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(145, 165, 172, 0.5)",
                          cursor: "pointer",
                        },
                      }}
                      align="center"
                      key={row}
                      tabIndex={-1}
                      onClick={() => props.handleSetTrainingSelected(row)}
                      selected={props.trainingSelected === id}
                    >
                      <ProjectCell entry={<strong>{title}</strong>} />
                      <ProjectCell entry={difficulty} />
                      <ProjectCell entry={point_value} />
                      <ProjectCell entry={training_url} />
                    </ProjectRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableCard>
    </div>
  );
};
